

















































import { Component, Vue } from "vue-property-decorator";
import DashCitasPendientesModule from "@/store/modules/Dashboards/DashCitasPendientes-module";
@Component({
  components: {
    EvolucionCitas: () => import("@/views/Dashboards/Evolucion-citas.vue"),
    MediaCitasAno: () => import("@/views/Dashboards/Media-Citas-ano.vue"),
    CitasPendietesRealizadas: () =>
      import("@/views/Dashboards/Citas-Pendientes-Realizdas.vue"),
    CitasPendietesVsRealizadas: () =>
      import("@/views/Dashboards/Citas-PendientesVsRealizadas.vue"),
    PacientesCitas: () => import("@/views/Dashboards/Pacientes-citas.vue"),
    PaciCita: () => import("@/views/Dashboards/Paci-cita.vue")
  }
})
export default class DisenoPC extends Vue {
  public tab: any = null;
  public get citasPaciente() {
    return DashCitasPendientesModule.DashCitasPendientess.length > 0;
  }
}
